import { Box, Heading, Link, Show, Text } from "@chakra-ui/react";
import { FC } from "react";
import { Logo } from "../Logo";
import { useTranslation } from "react-i18next";

export const ContactSection: FC = () => {
    const { t } = useTranslation();

    return (<>
        <Box display={"flex"} w='100%' justifyContent={'space-between'}>
            <Box display={"flex"} w={['60%', '100%']} justifyContent={"flex-start"} px={50}>
                <Box display={"block"} justifyContent={'space-between'}>
                    <Box display={"block"} justifyContent={'flex-start'}>
                        <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
                            <Box w='100%' display={"flex"} justifyContent={"flex"}>
                                {t('menu_contact')}
                            </Box>
                        </Heading>
                        <Link href='#'>
                            <Logo width={180} height={120} />
                        </Link>
                        <Text fontSize={{ base: 'lg', md: 'lg', lg: 'xl' }} textAlign={"left"} fontWeight={"bold"}>Compozeets Sp. z o.o.</Text>
                        <Text fontSize={{ base: 'md', md: 'md', lg: 'lg' }} textAlign={"left"}>Wały Piastowskie 1/1508</Text>
                        <Text fontSize={{ base: 'md', md: 'md', lg: 'lg' }} textAlign={"left"}>80-855 Gdańsk</Text>
                        <Text fontSize={{ base: 'md', md: 'md', lg: 'lg' }} textAlign={"left"}><Link href="mailto:info@compozeets.com">info@compozeets.com</Link></Text>
                        <Text fontSize={{ base: 'md', md: 'md', lg: 'lg' }} textAlign={"left"}><Link href="tel:+48516148212">+48 516 148 212</Link></Text>
                    </Box>
                    <Box display={"block"} justifyContent={"flex-start"} mt={10}>
                        <PromoInfo />
                    </Box>
                </Box>
            </Box>
            <Show above='lg'>
                <Box w='100%' h={"auto"} maxH={'500px'} display={"flex"} justifyContent={"flex-end"} px={50}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2324.917367702883!2d18.643946912813405!3d54.358441172485826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fd7371296a02f3%3A0x252bd3fdba3e64c7!2sWa%C5%82y%20Piastowskie%201%2F1508%2C%2080-855%20Gda%C5%84sk!5e0!3m2!1spl!2spl!4v1701986318058!5m2!1spl!2spl" width="100%" height="500" loading="lazy"></iframe>
                </Box>
            </Show>
        </Box>
        <Show below='lg'>
            <Box px={50}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2324.917367702883!2d18.643946912813405!3d54.358441172485826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fd7371296a02f3%3A0x252bd3fdba3e64c7!2sWa%C5%82y%20Piastowskie%201%2F1508%2C%2080-855%20Gda%C5%84sk!5e0!3m2!1spl!2spl!4v1701986318058!5m2!1spl!2spl" width="100%" height="500" loading="lazy"></iframe>
            </Box>
        </Show>
    </>
    );
}

const PromoInfo: FC = () => {
    const { t } = useTranslation();

    return <>
        <Text textAlign={"left"} fontWeight={500}>
            {t('contact_promo_phrase1')}
        </Text>
        <Text textAlign={"left"} fontWeight={500} mb={5}>
            {t('contact_promo_phrase2')}
        </Text>
    </>
}