import { FC } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next';

interface ShopModalProps {
  isOpen: boolean,
  onClose: () => void,
}

export const ShopModal: FC<ShopModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('shop_info')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {t('shop_plans')}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme='red' mr={3} onClick={onClose}>
            {t('button_ok')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}