import { FC } from 'react';

interface SygnetProps {
    height: number,
    width: number,
}

export const Sygnet: FC<SygnetProps> = ({ height, width }) => {
    return (

        <svg
            width={width}
            height={height}
            viewBox="700 600 2000 2000"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlSpace="preserve"
            style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                strokeLinejoin: "round",
                strokeMiterlimit: 2,
            }}
        >
            <rect
                x={0}
                y={0}
                width={3000}
                height={3000}
                style={{
                    fill: "white",
                }}
            />
            <g transform="matrix(1,0,0,1,841.946,0)">
                <path
                    d="M540.34,1607.01C544.227,1645.93 557.761,1675.2 583.145,1692.62C583.145,1692.62 547.109,1728.66 521.796,1753.97C506.072,1769.7 485.555,1779.73 463.489,1782.49C396.529,1790.86 248.705,1809.34 185.128,1817.28C166.178,1819.65 147.196,1813.14 133.692,1799.64C133.691,1799.64 133.691,1799.64 133.691,1799.64L540.34,1607.01Z"
                    style={{
                        fill: "url(#_Linear1)",
                    }}
                />
            </g>
            <g transform="matrix(1,0,0,1,841.946,0)">
                <path
                    d="M894.743,1684.91C914.641,1689.89 932.092,1701.84 943.92,1718.6C992.11,1786.87 1121.72,1970.48 1166.75,2034.27C1176.94,2048.71 1182.42,2065.96 1182.42,2083.64L1182.42,2120.67L668.755,1692.62C705.231,1686.29 733.524,1671.78 754.366,1649.82C754.366,1649.82 845.439,1672.59 894.743,1684.91Z"
                    style={{
                        fill: "url(#_Linear2)",
                    }}
                />
            </g>
            <g transform="matrix(1,0,0,1,841.946,0)">
                <path
                    d="M583.145,1521.4C611.682,1498.28 640.219,1484.13 668.755,1478.6L668.755,879.326L668.754,879.326C643.967,879.326 622.727,897.052 618.293,921.439C600.593,1018.79 557.35,1256.63 544.198,1328.96C541.655,1342.95 542.123,1357.32 545.571,1371.11C556.991,1416.79 583.145,1521.4 583.145,1521.4Z"
                    style={{
                        fill: "url(#_Linear3)",
                    }}
                />
            </g>
            <defs>
                <linearGradient
                    id="_Linear1"
                    x1={0}
                    y1={0}
                    x2={1}
                    y2={0}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(61.3006,115.983,-115.983,61.3006,432.386,1657.16)"
                >
                    <stop
                        offset={0}
                        style={{
                            stopColor: "rgb(153,0,0)",
                            stopOpacity: 1,
                        }}
                    />
                    <stop
                        offset={1}
                        style={{
                            stopColor: "rgb(255,0,0)",
                            stopOpacity: 1,
                        }}
                    />
                </linearGradient>
                <linearGradient
                    id="_Linear2"
                    x1={0}
                    y1={0}
                    x2={1}
                    y2={0}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(110.048,-115.077,115.077,110.048,813.538,1813.27)"
                >
                    <stop
                        offset={0}
                        style={{
                            stopColor: "rgb(153,0,0)",
                            stopOpacity: 1,
                        }}
                    />
                    <stop
                        offset={1}
                        style={{
                            stopColor: "rgb(255,0,0)",
                            stopOpacity: 1,
                        }}
                    />
                </linearGradient>
                <linearGradient
                    id="_Linear3"
                    x1={0}
                    y1={0}
                    x2={1}
                    y2={0}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(126.17,0,0,126.17,542.585,1346.28)"
                >
                    <stop
                        offset={0}
                        style={{
                            stopColor: "rgb(255,0,0)",
                            stopOpacity: 1,
                        }}
                    />
                    <stop
                        offset={0.99}
                        style={{
                            stopColor: "rgb(153,0,14)",
                            stopOpacity: 1,
                        }}
                    />
                    <stop
                        offset={1}
                        style={{
                            stopColor: "rgb(158,10,14)",
                            stopOpacity: 1,
                        }}
                    />
                </linearGradient>
            </defs>
        </svg>
    )
};
