import { Box, Heading, Text } from "@chakra-ui/react";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export const About: FC = () => {
    const { t } = useTranslation();

    return (
        <Box w='100%' display={'flex'} justifyContent={"flex-start"} bgColor={'whiteAlpha.50'} my={5}>
            <Box display={"block"} mb={5} px={50}>
                <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
                    <Box w='100%' display={"flex"} justifyContent={"flex-start"} my={5}>
                        Compozeets
                    </Box>
                </Heading>
                <Box mx={'auto'} my={5}>
                    <Text align={"justify"}>
                        {t('about_phrase1')}
                    </Text>
                </Box>
                <Box mx={'auto'} mb={5}>
                    <Text align={"justify"}>
                        {t('about_phrase2')}
                    </Text>
                </Box>
                <Box mx={'auto'} mb={5}>
                    <Text align={"justify"}>
                        {t('about_phrase3')}
                    </Text>
                </Box>
            </Box>
        </Box>
    );
}