import React from "react";
import slide1 from './slide_1.jpg';
import slide2 from './slide_2.jpg';
import slide3 from './slide_3.jpg';

export const carouselData = [
  {
    title: 'carousel_title1',
    text: 'carousel_description1',
    image: slide1,
  },
  {
    title: 'carousel_title2',
    text: 'carousel_description2',
    image: slide2,
  },
  {
    title: 'carousel_title3',
    text: 'carousel_description3',
    image: slide3,
  },
]

export const lngs: any = {
  en: { nativeName: 'English' },
  pl: { nativeName: 'Polish' }
};
