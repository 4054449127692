import { Box, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Link, Show, Spacer, Text, useBoolean, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { FC } from 'react';
import { Logo, Sygnet } from '../Logo';
import { FeatureIcon } from '../FeatureIcon';
import { GrMenu } from 'react-icons/gr';
import { ShopModal } from '../ShopModal';
import { useTranslation } from 'react-i18next';
import { LangSwitcher } from '../LangSwitcher';

export const Header: FC = () => {
    const [isDrawer, useDrawer] = useBoolean(false);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { t } = useTranslation();

    const modalOnMobieClick = () => {
        useDrawer.off()
        onOpen()
    }

    return (
        <Box w={'100%'} display='flex' h={{ base: "10rem", lg: "6.8rem" }} justifyContent={'space-between'} >
            <Show above='lg'>
                <Box marginStart={10}>
                    <Link href='#'>
                        <Logo width={180} height={110} />
                    </Link>
                </Box>
            </Show>
            <Show below='lg'>
                <Box marginStart={0}>
                    <Link href='#'>
                        <Sygnet width={200} height={180} />
                    </Link>
                </Box>
            </Show>
            <Spacer />
            <Box p='6'>
                <Show above='lg'>
                    <nav>
                        <Box display='flex' pt={6}>
                            <Box px={4}>
                                <Link onClick={onOpen}>
                                    <Text as='b' fontSize={{ base: 'lg' }} color={useColorModeValue('gray.700', 'gray.200')}>{t('menu_shop')}</Text>
                                </Link>
                            </Box>
                            <Box px={4} mr={1}>
                                <Link href='#contact'>
                                    <Text as='b' fontSize={{ base: 'lg' }} color={useColorModeValue('gray.700', 'gray.200')}>{t('menu_contact')}</Text>
                                </Link>
                            </Box>
                            <Box px={4}>
                                <LangSwitcher />
                            </Box>
                        </Box>
                    </nav>
                </Show>
                <Show below='lg'>
                    <Box mt={10}>
                        <FeatureIcon icon={GrMenu} width={6} height={6} onClick={useDrawer.on} />
                    </Box>
                </Show>
            </Box>
            <ShopModal onClose={onClose} isOpen={isOpen} />
            <Show below='lg'>
                <Drawer onClose={useDrawer.off} isOpen={isDrawer} size={'full'}>
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader><Logo width={180} height={120} /></DrawerHeader>
                        <DrawerBody>
                            <nav>
                                <Box display='block' justifyContent={'left'}>
                                    <Box px={4} mb={10}>
                                        <Link onClick={modalOnMobieClick}>
                                            <Text as='b' fontSize={{ base: 'lg' }} color={useColorModeValue('gray.700', 'gray.200')}>{t('menu_shop')}</Text>
                                        </Link>
                                    </Box>
                                    <Box px={4} mb={10}>
                                        <Link href='#contact' onClick={useDrawer.off}>
                                            <Text as='b' fontSize={{ base: 'lg' }} color={useColorModeValue('gray.700', 'gray.200')}>{t('menu_contact')}</Text>
                                        </Link>
                                    </Box>
                                    <Box px={4}>
                                        <LangSwitcher />
                                    </Box>
                                </Box>
                            </nav>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
            </Show>
        </Box>
    )
} 