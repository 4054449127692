import { Icon } from "@chakra-ui/react";
import { FC } from "react";
import { IconType } from 'react-icons';

interface IconProps {
    icon: IconType,
    width?: number,
    height?: number,
    onClick?: () => void
}

export const FeatureIcon: FC<IconProps> = ({ icon, width, height, onClick }) => {
    return (
        <Icon as={icon} w={width ? width : 10 } h={height ? height : 10} onClick={onClick ? () => onClick() : () => {}}/>
    );
}
