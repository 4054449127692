import { FC } from "react"
import {
  ChakraProvider,
  Box,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { Header } from "./components/Header"
import { Content } from "./components/Content"
import { Footer } from "./components/Footer"
import { theme } from "./components/Theme"

export const App: FC = () => (
  <ChakraProvider theme={theme}>
    <Box w='100%' bg='whiteAlpha.50'>
    <Box textAlign="center" bg='whiteAlpha.50'>
      <Grid minH="100%" templateAreas={`"header"
                            "main"
                            "footer"`}
        p={0}>
        <GridItem area={'header'} bg='whiteAlpha.50' p={0} maxWidth={'100%'}>
          <Box w={'100%'} display={"flex"} justifyContent={"center"}>
            <Header />
          </Box>
        </GridItem>
        <GridItem minH="90vh" bg='whiteAlpha.50' area={'main'}>
          <Box w={'100%'} display={"flex"} justifyContent={"center"}>
            <Content />
          </Box>
        </GridItem>
        <GridItem bg='blue.300' area={'footer'}>
          <Footer />
        </GridItem>
      </Grid>
    </Box>
    </Box>
  </ChakraProvider>
)
