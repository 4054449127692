import { Image, Box, Text, Heading } from "@chakra-ui/react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { carouselData } from "../../data";

export const ImageSlider: FC = () => {
  const { t } = useTranslation();

  return (
    <Carousel key={'slider'} autoPlay infiniteLoop swipeable interval={4500} transitionTime={500} showThumbs={false} showStatus={false} useKeyboardArrows>
      {carouselData.map((slide, index) => {
        return (
          <Box key={index} position={"relative"}>
            <Image src={slide.image} maxHeight={'500px'} height="auto" width="100%" fit={"cover"} aspectRatio={"16:9"} loading="lazy" />
            <Box position={"absolute"} top={0} w={'100%'} h={'100%'} m={"auto"} display={"flex"}>
              <Box w='90%' display={"block"} justifyContent={"center"} my={"auto"} mx={"auto"} backgroundColor={'rgba(255,255,255,0.4)'} borderRadius={25} py={5} px={1}>
                <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
                  <Box w='100%'>
                    {t(slide.title)}
                  </Box>
                </Heading>
                <Text fontSize={{ base: 'lg', lg: 'xl' }}>
                  {t(slide.text)}
                </Text>
              </Box>
            </Box>
          </Box>
      )})}
    </Carousel>
  );
};
