import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { lngs } from '../../data/data';
import { EnFlag, PlFlag } from '../../data';
import { Box, Fade, HStack, Show, VStack, useDisclosure } from '@chakra-ui/react';

interface LangFlagsPros {
    changeLang: (lng: string) => void;
}

export const LangSwitcher: FC = () => {
    const { isOpen, onToggle } = useDisclosure();
    const { i18n } = useTranslation();

    const changeLang = (lng: string) => {
        i18n.changeLanguage(lng);
        onToggle();
    }

    return (<>
        {!!!isOpen && <Box onClick={() => onToggle()}>
            {i18n.resolvedLanguage === 'en' ? <EnFlag width={25} height={25} /> : <PlFlag width={25} height={25} />}
        </Box>}
        <Fade in={isOpen}>
            <Show above='lg'>
                <HStack spacing={15}>
                    <LangFlags changeLang={changeLang} />
                </HStack>
            </Show>
            <Show below='lg'>
                <VStack spacing={15} align='flex-start'>
                    <LangFlags changeLang={changeLang} />
                </VStack>
            </Show>
        </Fade>
    </>
    )
}

const LangFlags: FC<LangFlagsPros> = ({ changeLang }) => {
    const { i18n } = useTranslation();
    return (<>{Object.keys(lngs).map((lng) => (
        <button key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => { changeLang(lng) }}>
            {lngs[lng].nativeName === 'English' ? <EnFlag width={25} height={25} /> : <PlFlag width={25} height={25} />}
        </button>
    ))}</>)
}