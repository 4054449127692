import {
  Box,
  Container,
  Link,
  Show,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from '@chakra-ui/react'
import { FaLinkedinIn } from 'react-icons/fa'
import { ReactNode, FC } from 'react'

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode
  label: string
  href: string
}) => {
  return (
    <Box
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}>
      <VisuallyHidden>{label}</VisuallyHidden>
      <Link isExternal href={href}>{children}</Link>
    </Box>
  )
}

export const SmallWithLogoLeft: FC = () => {
  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}>
      <Container
        as={Stack}
        maxW={'none'}
        py={5}
        direction={{ base: 'column', md: 'row' }}
        spacing={1}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}
        paddingStart={50}
        paddingEnd={50}>
        <Show above='lg'>
          <Text>© 2023 Compozeets. All rights reserved</Text>
          <Stack direction={'row'} spacing={6}>
            <SocialButton label={'Linkedin'} href={'https://www.linkedin.com/company/compozeets/'}>
              <FaLinkedinIn />
            </SocialButton>
          </Stack>
        </Show>
        <Show below='lg'>
          <Stack direction={'row'} spacing={6}>
            <SocialButton label={'Linkedin'} href={'https://www.linkedin.com/company/compozeets/'}>
              <FaLinkedinIn />
            </SocialButton>
          </Stack>
          <Text>© 2023 Compozeets. All rights reserved</Text>
        </Show>
      </Container>
    </Box>
  )
}