import { FC } from "react";
import { Box } from "@chakra-ui/react";
import { ImageSlider } from "../ImageSlider";
import { ContactSection } from "../ContactSection";
import { About } from "../About";

export const Content: FC = () => {
    return (
        <Box w='100%' display={"block"}>
            <section id="slider">
                <ImageSlider />
            </section>
            <section id="about">
                <About />
            </section>
            <section id="facts">
                {/* <Facts /> */}
            </section>
            <section id="promo">
                {/* <Box maxH={'1410px'} h='100%' bg={'whiteAlpha.50'}
                    bgImage={field} bgSize='cover' bgAttachment='fixed' bgPos='50% 100%' pos='relative' bgRepeat='no-repeat' >
                    <PromoInfo />
                </Box> */}
            </section>
            <section id="gallery">
            
            </section>
            <section id="services">
                {/* <Video /> */}
            </section>
            
            <section id="contact">
                <ContactSection />
            </section>
        </Box>
    )
}