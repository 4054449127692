import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: ['pl', 'en'],
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      pl: {
        translation: {
          menu_shop: "Sklep",
          menu_contact: "Kontakt",
          about_phrase1: "Witaj w świecie Compozeets – Twojego partnera w dziedzinie dostaw produktów do naprawy kompozytów, zwłaszcza dla branży energetyki wiatrowej. Jesteśmy specjalistyczną firmą, oferującą szeroki zakres produktów, niezbędnych do skutecznych napraw i konserwacji kompozytowych konstrukcji. Nasz asortyment obejmuje wysokiej jakości włókna szklane, żywice poliestrowe i epoksydowe, szpachle, farby, balsy i pianki, a także niezbędne akcesoria i narzędzia, które umożliwiają wykonywanie prac w bezpiecznych warunkach.",
          about_phrase2: "Dążymy do zapewnienia naszym klientom kompleksowego wsparcia na każdym etapie projektu. Nasz zespół doświadczonych specjalistów jest gotów służyć fachowym doradztwem i pomocą techniczną, abyś mógł z powodzeniem zrealizować swoje przedsięwzięcia.",
          about_phrase3: "Jeśli masz pytania dotyczące naszych produktów lub chciałbyś dowiedzieć się, czy możemy spełnić Twoje indywidualne potrzeby, serdecznie zachęcamy do kontaktu. Jesteśmy tu po to, aby być Twoim partnerem w sukcesie i zapewnić Ci niezbędne rozwiązania do skutecznej naprawy kompozytów.",
          carousel_title1: "Chemikalia do konserwacji i naprawy kompozytów",
          carousel_title2: "Narzędzia i wyposażenie",
          carousel_title3: "Konsultacje i szkolenia",
          carousel_description1: "Nasze produkty są starannie wyselekcjonowane i przetestowane, aby zapewnić długotrwałą i niezawodną ochronę przed czynnikami atmosferycznymi oraz zużyciem.",
          carousel_description2: "Wspieramy naszych klientów w wyborze odpowiednich narzędzi i wyposażenia do serwisowania kompozytów.",
          carousel_description3: "Nasz zespół ekspertów zawsze jest gotów by dzielić się wiedzą i doświadczeniem w dziedzinie serwisowania kompozytów.",
          contact_promo_phrase1: "Współpracujmy razem i stwórzmy przyszłość odnawialnych źródeł energii.",
          contact_promo_phrase2: "Skontaktuj się z nami już teraz!",
          shop_info: "Sklep w trakcie budowy.",
          shop_plans: "Przewidywany czas uruchomienia Q2 2024.",
          button_ok: "OK"
        }
      },
      en: {
        translation: {
          menu_shop: "Shop",
          menu_contact: "Contact",
          about_phrase1: "Welcome to the world of Compozeets – your partner in the supply of products for composite repairs, especially for the wind energy industry. We are a specialized company offering a wide range of products necessary for effective repairs and maintenance of composite structures. Our assortment includes high-quality fiberglass, polyester and epoxy resins, putties, paints, fillers, and foams, as well as essential accessories and tools that enable work in safe conditions.",
          about_phrase2: "We strive to provide our customers with comprehensive support at every stage of the project. Our team of experienced specialists is ready to offer professional advice and technical assistance so that you can successfully complete your endeavors.",
          about_phrase3: "If you have any questions about our products or would like to find out if we can meet your individual needs, we encourage you to contact us. We are here to be your partner in success and provide you with the necessary solutions for effective composite repairs.",
          carousel_title1: "Chemicals for Conservation and Repair of Composites",
          carousel_title2: "Tools and Equipment",
          carousel_title3: "Consultations and Training",
          carousel_description1: "Our products are carefully selected and tested to provide long-lasting and reliable protection against weather conditions and wear.",
          carousel_description2: "We support our customers in choosing the right tools and equipment for servicing composites.",
          carousel_description3: "Our team of experts is always ready to share knowledge and experience in the field of composite servicing.",
          contact_promo_phrase1: "Let's collaborate and create the future of renewable energy sources.",
          contact_promo_phrase2: "Contact us now!",
          shop_info: "Shop under construction.",
          shop_plans: "Expected launch in Q2 2024.",
          button_ok: "OK"
        }
      }
    }
  });


export default i18n;